.item-flex-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.item-flex-row-start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.item-flex-row-end{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.item-flex-row-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.pic-list{
    flex-wrap: wrap;
    align-items: stretch;
    max-height: 50vh;
    overflow-y: scroll;
    margin-bottom: 20px;
}
.pic-item{
    width: 220px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s linear;
    position: relative;
    margin: 5px;
    border: 1px solid #ddd;
}
.pic-item:hover{
    background: rgba(0, 0, 0, 0.15);
}
.pic-item .bar{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0,  0.7);
    height: 50px;
    padding-right: 10px;
    padding-left: 10px;
    color: white;
    z-index: 80;
}
.pic-item .selected{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-out;
    overflow: hidden;
    font-size: 13px;
    z-index: 82;
    background: #2db7f5;
}
.pic-item img{
    max-width: 100%;
    max-height: 150px;
    cursor: pointer;
}