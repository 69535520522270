.inc-header {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-size: 16px;
}
  
.site-layout-sub-header-background {
    background: #fff;
}
  
.site-layout-background {
    background: #fff;
}

.main-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.cnt-item{
    width: 100%;
}
.top-part{
    padding: 10px;
}
.footer-part{
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}
.table-row{
    font-size: 12px !important;
}