.main {
	position: relative;
	width: 100vw;
	height: 100vh;
	background: #f5f5f5;
	display: flex;
	justify-content: center;
	align-items: center;
    background-image: url("https://www.dfsykj.com/srcfile/admin_frontend/imgs/df_admin_bg.jpeg");
    background-repeat:no-repeat;
    background-size: cover;
}

.login-form {
	background: #fff;
	width: 26%;
	position: relative;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.head {
	position: absolute;
	left: 0;
	right: 0;
	top: 20px;
	display: flex;
	justify-content: center;
}

.head img {
	width: 100px;
	height: 100px;
	/* padding: 10px; */
	display: block;
	background: #fff;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-o-border-radius: 50%;
	-moz-border-radius: 50%;
	/* border: 6px solid rgba(0, 0, 0, 0.1); */
}

.main h1 {
	font-size: 25px;
	color: #676767;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	padding-top: 130px;
	text-align: center;
}

.main form {
	width: 80%;
	margin: 0 auto;
	padding: 6% 0 9% 0;
}

.main p {
	text-align: center;
}

.main form p a {
	color: #888;
	font-family: 'Open Sans', sans-serif;
}

form p a:hover {
	color: #21A957;
}

/*-----start-responsive-design------*/
@media (max-width:1440px) {
	.login-form {
		width: 30%;
	}
}

@media (max-width:1366px) {
	.login-form {
		width: 32%;
	}
}

@media (max-width:1280px) {
	.login-form {
		width: 34%;
	}
}

@media (max-width:1024px) {
	.login-form {
		width: 45%;
	}
}

@media (max-width:768px) {
	.login-form {
		width: 59%;
	}
}

@media (max-width:640px) {
	.login-form {
		width: 63%;
	}
}

@media (max-width:480px) {
	.login-form {
		width: 74%;
	}
}

@media (max-width:320px) {
	.login-form {
		width: 85%;
	}
}
.username{
	margin-top: 10px;
}
.password{
	margin-top: 10px;
}
.submit{
	margin-top: 20px;
}